import "bootstrap";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

 Fancybox.bind('[data-fancybox]', {
      Html: {
         video: {
            autoplay: true,
            ratio: 16 / 9,
         },
      },
   })

var $ = require("jquery");
window.jQuery = $;
window.$ = $;

import Cookies from "js-cookie";
// This is all you.
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
SwiperCore.use([Navigation, Pagination])

import Swiper from 'swiper'

new Swiper('.swiper-container', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
})

$(function() {

    $(".accordion-button").on('click', function() {
        if ($(window).width() < 992) return;
        if (!$(this).attr('aria-expanded') || $(this).hasClass('collapse-done')) return;
        $(this).addClass('collapse-done');
        var answer = $(this).parents('.accordion-header').siblings('.accordion-collapse');
        setTimeout(function(){
            var temp_text = '';
            var temp_parent = null;
            var count = 0;
            $.each($(answer).find(".accordion-body"), function( index, item ){
                $(item).css('position', 'relative');
                var parent = $(item).find(".row>div:nth-of-type(6)");
                var el = $(parent).find(".accordion-table-content p");
                var text = el.text();
                if (temp_text !== '' && temp_parent !== null && temp_text === text) {
                    count++;
                    //el.remove();
                    var height = $(parent).height();
                    $(temp_parent).height($(temp_parent).height() + height).css({
                        position: "absolute", right: '0', top: $(item).find(".row>div:nth-of-type(3)").height(), display: 'flex', justifyContent:
'center', alignItems: 'center', zIndex: '1'
                    });
                    if (count === 1) {
                        $(temp_parent).after($(parent).clone());
                    }
                }else { temp_parent = parent; count = 0; }
                temp_text = text;
            });
        }, 100);
    });


    $(".accordion-item button:first").trigger("click");

    $('#year').on('change', function() {
        var year = $(this).val()
        window.location.href = year;
    });


    $('.navbar-toggler').click(function () {
        $('.site-header').toggleClass('bg-white')
    })
    $('.activePageSlider').addClass('active')



    $('.search-on').click(function () {
        $('.search-full').addClass('active')
    })
    $('.close-search').click(function () {
        $('.search-full').removeClass('active')
    })


    var url = window.location.href
    $('.navbar-nav .nav-link').each(function () {
        if (url == this.href) {
            $(this).closest('li').addClass('active')
            $(this).closest('li').parent().parent().addClass('active')
        }
    })

    $('body').attr('id',$('.page').data('bodyid'));
;
	// add background to all overlay pages
	$("#contents.withBgOverlay").prepend('<div id="leftBgOverlay" class="animated slideInLeft"></div>');

	$("#contents.withBgOverlay #context").append(
		'<div class="bottomshadow"></div>'+
		'<a title="Scroll to Top" href="javascript:;" class="gototop"><i class="fa fa-angle-up"></i></a>'
	);

	$("#contents.withBgOverlay #sidebar ul.list-unstyled li.active a").prepend('<i class="fa fa-caret-right"></i>');

    function filterAutoFocus(){
        var URL = window.location.href;
        if (URL.includes("search=")){
            $("input[name='search']").focus();  // TODO emin olamadım kontrol edilmesi gerekebilir.
        }
    }

    function fadeOutOverlay(){
        $('.pagebg,#site-footer').removeClass('pageblur');
        $('.overlaia').hide();
    }


	if($("#products").length > 0 || $("#results").length > 0){
		if(Cookies.get('sf-saglik-personeli') == 1){
			// console.log("cookie daha önce açılmış");
		}
		else{
			$('.pagebg,#site-footer').addClass('pageblur');
			$('.overlaia').show();
			$('.overlaia .btn-primary').click(function(){
				fadeOutOverlay();
				Cookies.set('sf-saglik-personeli', 1,{expires: 1/48}); //yarım saat
				return false;
			});
		}
	}
	if($(".page").attr('data-bodyid') == 'contact'){
		$('.closeOverlay').click(function(event) {
			event.preventDefault();
			$('.overlaia').fadeOut(400);
		});
	}

	$(window).trigger('resize');
    filterAutoFocus();
});




